
import Vue from "vue";
// @ts-ignore
import { mapActions } from "vuex";
// @ts-ignore
import Dialog from "@/components/Content/Dialogs/Default.vue";
// @ts-ignore
import CardActions from "@/components/Content/CardAction.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
    name:"ConfirmDeleteReport",
    props:{
        open:{
            type: Boolean,
            default: false
        }
    },
    components:{
        Dialog,
		CardActions,
    },
    data: () => ({}),
    created(){},
    mounted(){},
    computed:{},
    methods:{
        ...mapActions("loading", ["setLoadingData"]),
		...mapActions("auth", ["ResetToken"]),

		handleAction(event: { type: string }) {
			const { type } = event;

			switch (type) {
				case "submit":
					this.handleActions();
					break;
				case "cancel":
					this.handleCancel();
					break;
			}
		},

        async handleCancel(){
            this.$emit('show-dialog-token', false);
        },

		async handleActions(){
			await this.setLoadingData(TypeLoading.loading);
			await this.ResetToken()
			.then(async () => {
				await this.setLoadingData();
				this.handleCancel();
			})
			.catch(async () => {
				await this.setLoadingData();
				this.handleCancel();
			});
        }
    },
    watch:{}
})
